
.formRespondNow {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-radius: 50px;
    flex-wrap: unset !important;
    gap: 20px !important;
    transition: opacity 0.5s ease-in-out;
}


.guestTable th, .guestTable td {
    border: 2px solid black; /* Block border */
    padding: 8px;
    text-align: center;
}

.guestTable {
    border-collapse: collapse;
    margin-bottom: 20px;
}

.guestTable input[type="checkbox"] {
    accent-color: #637e45;
}

.guestTable select {
    background-color: antiquewhite
}
.select.decorated option:hover {

    background-color: #637e45 !important;
}


.guestTable tr:hover {
    background-color: #cb6843; /* Hover color */
    color: antiquewhite;
}

.guestTable th {
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #cb6843;
    color: antiquewhite !important;
}

.guestTable button:hover {
    border-color: antiquewhite !important;
    color: antiquewhite !important;
}
.inputTextBox {
    width: 300px !important;
    text-align: center !important;
}

.labelTop {
    margin-bottom: 10px;
    font-size: 3rem !important;
    letter-spacing: 5px;
}

.formGroup {
    display: flex;
    align-items: center !important;
    margin-bottom: 15px;
    justify-content: center !important;
}

.formGroup label {
    margin-right: 10px;
    min-width: 100px;
    /* Adjust as needed */
}

.formGroup input {
    flex: 1;
    padding: 8px;
    font-size: 16px;
    width: 100%;
    max-width: 300px !important;
    /* Set a maximum width for the input fields */
    box-sizing: border-box;
}

.labelBot {
    margin-top: 5%;
}

.buttonSubmit:hover {
    box-shadow: 0 0 3px goldenrod, 0 0 5px goldenrod !important;
}

;

.guestInfo {
    width: 100%;
    margin-bottom: 20px;
}

.formGroup {
    margin-bottom: 15px;
}

.formGroup label {
    display: flex;
    flex-direction: column;
    font-weight: bold;
}

.formGroup input[type="email"],
.formGroup input[type="tel"],
.formGroup input[type="checkbox"] {
    margin-top: 5px;
    padding: 8px;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
}

.button {
    margin: 5px;
}

.footerContainer {
    display: flex !important;
    justify-content: space-around !important;
    width: 100% !important;
    margin-top: 150px !important;
}

.buttonGroup {
    display: flex !important;
    justify-content: center !important;
    padding: 10%;
    gap: 30% !important;
}

.buttonStep {
    display: flex !important;
    justify-content: center !important;
    gap: 30% !important;
}

.validateStep {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
}

/* Optionally, target the menu items */
.inputTextBox .dropdown-item {
    background-color: #cb6843 !important;
}

@media (max-width: 750px) {
    .formRespondNow > p {
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 20px;
    }
    .guestTable {
        all: unset;
        border-collapse: collapse;
    }
    .labelTop {
        font-size: 2rem !important;
    }
    .buttonGroup {
        display: flex !important;
        flex-direction: column;;
        justify-content: center !important;
        padding: 10%;
        gap: 10px !important;
    }
    
    .buttonStep {
        display: flex !important;
        justify-content: center !important;
        gap: 30% !important;
        /* 30% gap between buttons */
    }
    
}
