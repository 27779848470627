/* Ensure the body and html take full height */
html, body {
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
}

#root {
    height: 100vh;
    margin: 0;
    padding: 0;
    white-space: pre-line;
}


a {
    color: #000000;
}

body {
    font-family: "Cormorant Garamond", serif !important;
    background-color: antiquewhite !important;
}

.backgroundDiv {
    /** background image */
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100% !important;
    height: 100% !important;
    margin-left: 0% !important;
    margin-right: 0% !important;
}

/*Planing section */
.planning {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 150px;
    background-color: #cb6843;
}

.planning-image-container {
    width: 50%; /* Full width */
    object-fit: cover;
    font: inherit;
}


.planning-image {
    height: 100%;
    object-fit: cover;
    border-top-right-radius: 100% 100%; /* Create arch effect */
    border-bottom-right-radius: 100% 100%; /* Create arch effect */
}

.planning-list {
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
    color: antiquewhite !important;
    gap: 30px !important;
}

.planning-icon {
    width: 50px !important; /* Adjust the width as needed */
    height: auto !important; /* Maintain aspect ratio */
    color: antiquewhite !important;
    margin-right: 30px !important; /* Add some space on the left */
    animation: fadeIn;
    animation-timeline: view();
    animation-range-start: cover 0%;
    animation-range-end: cover 20%;
}
.planning h1 {

    font-weight: 700;
}

.planning h2 {

    font-weight: 200!important;
}

.planning p {
    font-weight: 300 !important;
}

/* Accommodation section */
.accommodation-container {
    margin-top: 30%;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.accommodation-container h1 {
    font-weight: 700;
    font-size: calc(2em + 1vw);
}
.accommodation-container p {
    font-weight: 300;
    font-size: calc(0.5em + 1vw);
    margin-bottom: 0;
}


.accommodation-card {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px;
    background-color: transparent;
}

.accommodation-image-flower-bottom, .accommodation-image-flower-top {
    width: 700px;
    height: 300px;
}

.accommodation-image-flower-bottom {
    transform: scaleY(-1);
}


.activities {
    margin-top: 30%;
    text-align: center;
    padding: 20px;
    background-color: #eeca9c !important;
    color: black !important;
}

.activities a {
    color: black; /* Set link color to black */
    
    font-size: 1em;
}

.activities h1 {
    font-weight: 700;
    font-size: calc(2em + 1vw);
}

.activities h2 {
    font-weight: 500;
    font-size: calc(1em + 1vw);
    margin-bottom: 2%;
}

.activities-other {
    font-weight: 300;
    font-size: calc(1em + 1vw);
    margin-bottom: 2%;
}
.activity-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0 !important;
    border: unset !important;
    background-color: transparent !important;
    font-size: larger;
    color: black !important;
}

.actvite-carousel {
    margin-top: 100px;
    object-fit: cover;
}
.card-title {
    font-size: 2em !important;
}
.carousel-caption {
    all: unset;
}
.carousel-indicators  {
    position: relative !important;

}

.carousel-indicators  {
    position: relative !important;

}

.carousel-indicators [data-bs-target]  {
    background-color: #cb6843 !important;

}
.activity-card img {
    width: 600px;
    height: 500px;
    object-fit: cover;
}

.contribution {
    margin-top: 30%;
    background-color: transparent;
    text-align: center;
    background-color:  #cb6843 !important;
    border-top-left-radius: 100% 100%; /* Create arch effect */
    border-top-right-radius: 100% 100%;
}
.contribution-card {
    background-color:  transparent  !important;
    border: unset !important;
}
.contribution h1 {
    font-weight: 700 !important;
    font-size: calc(3em + 1vw) !important;
}

.contribution-card p {
    font-size: 1.25em;
    font-weight: 500;
}
.contribution-info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.contribution-image {
    width: 50%;
    height: auto;
    object-fit: cover;
}
.footer {
    background-color: #cb6843;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: auto;
}

.footer-respondNow {
    display: none !important;
}


.social-icon {
    color: #000  !important;
    margin: 10px  !important;
    transition: color 0.3s  !important;
}

.social-icon:hover {
    color: antiquewhite  !important;
}

.accommodation-logo {
    width: 75px !important; /* Adjust the width as needed */
    height: auto !important; /* Maintain aspect ratio */
    max-height: 100px !important; /* Ensure the height does not exceed a certain value */
}



.home { 
    background-color: transparent;
    margin-left: 5%;
    margin-right: 1%;
}

.home-image-name {
    min-width: 500px;
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.home-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.home-title-container {
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    justify-content: flex-end;
    margin-top: auto;
    margin-bottom: 20px;
}

.home-name-content {
    font-size: calc(2em + 2vw);
    font-family: "Cormorant Garamond", serif;
    margin-bottom: 10%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    animation: fadeIn 5s;

}

.home-subname-container {
    display: flex;
    align-items:flex-start;
    justify-content:space-between;
}

.image-container {
    position: relative;
}


.home-image-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.home-image-flower-right, .home-image-flower-left {
    width: auto;
    height: 400px;
    animation: fadeInUp 3s;
    z-index: -1;
}

.address-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;

    margin-top: 20px;
}
.address-container p {
    font-size: 1.5em;
    font-weight: 500;
}

.address-container a {
    color: black; /* Set link color to black */
    text-decoration: none; /* Remove underline */
    font-size: 1em;
}

.address-container a:hover {
    text-decoration: underline; /* Add underline on hover */
}


.main-image {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;

    border: 30px solid  #cb6843; /* Add black border */
    border-top-width: 1px;
    border-bottom-width: 0px;
    border-top-left-radius: 100% 100%; /* Create arch effect */
    border-top-right-radius: 100% 100%; /* Create arch effect */

}



@media (max-width: 768px) {


    .home {
        margin-top: 0%;
        margin-left: 0%;
    }

    .home-image-name {
        min-width: unset;
    }
    .home-content {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

    }
    .home-title-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-bottom: 0 !important;
    }
    .home-name-content {
        font-size: 30px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

    }

    .home-subname-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

    }

    .address-container p {
        font-size: 1em;
        font-weight: 500;
    }
    
    .address-container a {
        font-size: 1em;
    }

    .image-container {
        width: auto; /* Full width */
        height: auto; /* Adjust height automatically */
        margin-left: 0; /* Reset margin */
        margin-top: 5%; /* Add some space between elements */
        margin-right: 0;;
    }
    .home-image-flower-right, .home-image-flower-left {
        width: auto;
        height: 200px;
    }
    

    .address-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        text-align: center; /* Center text */
        gap: 10px;
        font-size: 12px;
        margin-top: 10px;
    }

    .main-image {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
    
        border: 10px solid #cb6843; /* Add black border */
        border-top-width: 1px;
        border-bottom-width: 0px;
        border-top-left-radius: 100% 100%; /* Create arch effect */
        border-top-right-radius: 100% 100%; /* Create arch effect */
    
    }

    .planning {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
        background-color: #cb6843;
        text-align: center;
        color: white !important;
        margin-top: 0%;
        align-items: center;
        padding-top: 100px;
        padding-bottom: 0;
    }
    
    .planning-image {
        object-fit: cover;
        width: 100%;
    }

    .planning-image-container {
        justify-self: left;
        width: 100%; /* Full width */
        object-fit: cover;
        font: inherit;
    }
    
    .planning-list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: center;
        color: antiquewhite !important;
        gap: 30px !important;
        margin-left: 0% !important;
        margin-bottom: 10% !important;
    
    }
    .planning-list > * {
        margin-left: 0% !important;

    }

    .planning-col > h2  {
        font-size: 20px !important;
    }
    
    .planning-icon {
        width: 40px !important; /* Adjust the width as needed */
        height: auto !important; /* Maintain aspect ratio */
        color: antiquewhite !important;
        margin-right: 5px !important; /* Add some space on the left */
    }

    .actvite-carousel {
        display: flex!important;
        flex-direction: column!important;
        align-items: center!important;
        margin-top: 50px!important;
    }
    .activities h1 {
        font-weight: 700;
        font-size: calc(2em + 1vw);
    }
    
    .activities h2 {
        font-weight: 500;
        font-size: calc(1em + 1vw);
        margin-bottom: 2%;
    }
    .card-title {
        font-weight: 700 !important;
        font-size: 1.5em !important;
        margin-bottom: 2%;
    }
    .activities p {
        font-weight: 500 !important;
        font-size: 0.85em !important;
        margin-bottom: 2%;
    }
    .activities-other p {
        font-weight: 300;
        font-size: 2em !important;
        margin-bottom: 2%;
    }
    .activity-card img {
        width: 300px;
        height: 400px;
        object-fit: cover;
    }

    .activity-card {

        padding: 10px;

    }
    
    .accommodation-container {
        margin-top: 60%;
        background-color: transparent;
    }
    .accommodation-image-flower-bottom, .accommodation-image-flower-top {
        width: 300px;
        height: 150px;
    }

    .accommodation-container h1 {
        font-weight: 700;
        font-size: calc(2em + 1vw);
    }
    .accommodation-container p {
        font-weight: 300;
        font-size: calc(1.5em + 1vw);
    }

    .contribution {

        border-top-left-radius: 0; /* Create arch effect */
        border-top-right-radius: 0;
        height: 80vh;
        
    }
    .contribution-card {
        background-color:  transparent  !important;
        border: unset !important;
    }
    
    .contribution-card p {
        font-size: 1.25em;
        font-weight: 500;
    }
    .contribution-image {
        width: 100%;
        height: auto;
        object-fit: cover;
    }
    .card-body {
        padding: 0 !important;
    }
    .social-icon {
        margin: 2px  !important;
    }
    
}