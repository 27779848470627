

.enterGuestInfo-response-button {
    border-radius: 15px !important; /* Adjust the radius as needed */
    font-size: 50px !important;
    background-color: transparent!important;
    border: 2px solid black!important; /* Adjust the border color as needed */
    padding: 10px 20px!important; /* Adjust the padding as needed */
    cursor: pointer!important;
    transition: background-color 0.3s ease, color 0.3s ease !important;
    color: black!important;
}

.enterGuestInfo-response-button:hover {
    color: antiquewhite !important;
    border-color: antiquewhite !important;
    background-color: #cb6843 !important;
}

.response-button:hover {
    color: #cb6843 !important;
    border-color: #cb6843 !important;
}

.response-button {
    border-radius: 15px!important; /* Adjust the radius as needed */
    background-color: transparent !important;
    border: 2px solid black !important; /* Adjust the border color as needed */
    color: black !important; /* Adjust the text color as needed */
    padding: 10px 20px !important; /* Adjust the padding as needed */
    font-size: 25px !important; /* Adjust the font size as needed */
    cursor: pointer !important;
    transition: background-color 0.3s ease, color 0.3s ease!important;
}

.find-guest-button {
    border-radius: 15px!important; /* Adjust the radius as needed */
    background-color: transparent !important;
    border: 2px solid black !important; /* Adjust the border color as needed */
    color: black !important; /* Adjust the text color as needed */
    padding: 10px 20px !important; /* Adjust the padding as needed */
    font-size: 25px !important; /* Adjust the font size as needed */
    cursor: pointer !important;
    transition: background-color 0.3s ease, color 0.3s ease!important;
    margin-bottom: 5%;
}

.find-guest-button:hover {
    color: #cb6843 !important;
    border-color: #cb6843 !important;
}

.tab-button {
    border-radius: 15px!important; /* Adjust the radius as needed */
    background-color: transparent !important;
    border: 2px solid black !important; /* Adjust the border color as needed */
    color: black !important; /* Adjust the text color as needed */
    font-size: 15px !important; /* Adjust the font size as needed */
    cursor: pointer !important;
    transition: background-color 0.3s ease, color 0.3s ease!important;
    margin-bottom: 5%;
}

.tab-button:hover {
    color: #cb6843 !important;
    border-color: #cb6843 !important;
}

.main-response-button {
    border-radius: 15px!important; /* Adjust the radius as needed */
    background-color: #cb6843 !important;
    border: 2px solid black !important; /* Adjust the border color as needed */
    color: antiquewhite !important; /* Adjust the text color as needed */
    padding: 10px 20px !important; /* Adjust the padding as needed */
    font-size: 35px !important; /* Adjust the font size as needed */
    cursor: pointer !important;
    transition: background-color 0.3s ease, color 0.3s ease!important;
    border-color: antiquewhite !important;
}

.main-response-button:hover {
    background-color: #637e45 !important;
    
}

.contribution-button {
    border-radius: 15px!important; /* Adjust the radius as needed */
    background-color: transparent !important;
    border: 2px solid black !important; /* Adjust the border color as needed */
    color: black !important; /* Adjust the text color as needed */
    padding: 10px 20px !important; /* Adjust the padding as needed */
    font-size: 25px !important; /* Adjust the font size as needed */
    cursor: pointer !important;
    transition: background-color 0.3s ease, color 0.3s ease!important;
}

.contribution-button:hover {
    color: antiquewhite !important;
    border-color: antiquewhite !important;
    background-color: #cb6843 !important;
    
}

@media screen and (max-width: 768px) {

    .response-button {
        border-radius: 15px!important; /* Adjust the radius as needed */
        background-color: transparent !important;
        border: 2px solid black !important; /* Adjust the border color as needed */
        color: black !important; /* Adjust the text color as needed */
        padding: 10px 20px !important; /* Adjust the padding as needed */
        font-size: 16px !important; /* Adjust the font size as needed */
        cursor: pointer !important;
        transition: background-color 0.3s ease, color 0.3s ease!important;
    }

    .find-guest-button {
        border-radius: 10px!important; /* Adjust the radius as needed */
    }
    
    
    .main-response-button {
        font-size: 16px !important; /* Adjust the font size as needed */
    }
    
    .buttonRespond {
        background-color: #F3A632 !important;
        color: #000 !important;
        font-family: "Josefin Sans", sans-serif;
        font-weight: 600;
        font-size: x-large;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%; /* Make the button round */
        width: 60px; /* Set a fixed width */
        height: 60px; /* Set a fixed height */
        position: fixed; /* Fix the button at the bottom right */
        bottom: 20px; /* Distance from the bottom */
        right: 20px; /* Distance from the right */
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a shadow for better visibility */
    }

    .buttonRespondCard {
        background-color: antiquewhite !important;
        border-color: #000 !important;
        font-family: "Josefin Sans", sans-serif !important;
        display: block !important;
        justify-content: center !important;
        align-items: center !important;
        color: #000 !important;
        font-weight: 600 !important;
        font-size: x-large !important;
        margin: 0 auto;
    }

    .enterGuestInfo-response-button {
        font-size: 20px !important;
    }
    
    
}
