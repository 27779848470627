
.navItems{
    padding-inline: 1vw !important;
    font-size: 1.5em !important;
}


.navItems-main {
    justify-content: right !important;
    align-items: start !important;
}

.navItem-main:hover > *{
    color: #cb6843 !important;
}

.nav-brand-image {
    width: 7vw !important;
    height: 7vw !important;
    animation: rotateIn 3s linear;
    object-fit: cover; /* Assurez-vous que l'image couvre toute la zone */
    z-index: 0; /* Assurez-vous que l'image est en arrière-plan */
}

.nav-brand-container {
    position: relative;
    display: inline-block;
}

.nav-brand-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1; /* Assurez-vous que le texte est au-dessus de l'image */
}

@media (max-width: 750px) {
    .nav-brand-image {
        width: 20vw !important;
        height: 20vw !important;
    }
    .nav-brand-text {
        font-size: 10px !important;
    }
    
}