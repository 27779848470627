.respond-now-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative; /* Ensure the container is the positioning context */
    min-height: 80vh; /* Full viewport height */
}

.respond-now-image-left, .respond-now-image-right {
    width: auto;
    height: 50vh; /* Full viewport height */
    object-fit: cover;
    position: absolute; /* Position the images absolutely */
    bottom: 0; /* Fix the images at the bottom */
    animation: fadeInUp 3s;
}

.respond-now-image-left {
    left: 0; /* Align the left image to the left */
    margin-left: 10%;
}

.respond-now-image-right {
    right: 0; /* Align the right image to the right */
    margin-right: 10%;
}

.welcom-page {
    height: 90vh; /* 90% of the viewport height */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.reponse-page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media (max-width: 768px) {
    .respond-now-image-left, .respond-now-image-right {
    display: none;
    }

    .respond-now-container {
        all: inherit;   
    }
    
}