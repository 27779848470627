@import url("https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/css/flag-icon.min.css");


.dropdown-menu {
    min-width: auto !important;
    background-color: transparent !important;
}

.dropdown-item {
    width: fit-content;
}
.dropdown-item:hover  {
    background-color: #3A8A71 !important;
}